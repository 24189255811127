import { Box, styled, Theme } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'
import { Typography } from '@carto/meridian-ds/components'

const StepContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '&:before, &:after': {
    content: '""',
    background: theme.palette.divider,
    position: 'absolute',
    top: theme.spacing(1.25),
    height: theme.spacing(0.25),
    width: `calc(50% - ${theme.spacing(2.5)})`
  },
  '&:before': {
    left: 0
  },
  '&:after': {
    right: 0
  },
  '&:first-of-type:before': {
    content: 'none'
  },
  '&:last-of-type:after': {
    content: 'none'
  }
}))

function getStepNumberColors({ theme, completed, selected }: { completed: boolean; selected: boolean; theme: Theme }) {
  if (completed) {
    return {
      background: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      color: theme.palette.common.white
    }
  } else if (selected) {
    return {
      background: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
      color: theme.palette.common.white
    }
  } else {
    return {
      borderColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary
    }
  }
}
const StepNumber = styled('span', { shouldForwardProp: (prop) => prop !== 'completed' && prop !== 'selected' })<{
  completed: boolean
  selected: boolean
}>(({ theme, completed, selected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(3),
  width: theme.spacing(3),
  marginBottom: theme.spacing(1),
  borderRadius: '50%',
  border: `2px solid currentColor`,
  ...theme.typography.caption,
  ...getStepNumberColors({ theme, completed, selected })
}))

function Step({ step, currentStep, label }) {
  const isSelected = step === currentStep
  const isCompleted = step < currentStep
  const labelColor = isCompleted ? 'success.main' : isSelected ? 'textPrimary' : 'textSecondary'

  return (
    <StepContainer>
      <StepNumber completed={isCompleted} selected={isSelected}>
        {isCompleted ? <CheckIcon fontSize="small" /> : step}
      </StepNumber>
      <Typography variant="caption" component="div" color={labelColor}>
        {label}
      </Typography>
    </StepContainer>
  )
}

export default Step
