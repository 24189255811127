import { useRef, useState, forwardRef, useEffect, useMemo, ReactNode, MutableRefObject } from 'react'
import { Tooltip, TooltipProps, styled } from '@mui/material'

const TooltipRoot = styled(Tooltip)(() => ({
  whiteSpace: 'nowrap',
  maxWidth: 999,
  pointerEvents: 'auto'
}))

const Content = styled('span', {
  shouldForwardProp: (prop) => prop !== 'ellipsisInFirstChild' && prop !== 'lines'
})<{ ellipsisInFirstChild?: boolean; lines?: number }>(({ ellipsisInFirstChild, lines = 1 }) => ({
  ...(lines === 1 && {
    ...(ellipsisInFirstChild
      ? {
          display: 'flex',
          flex: '1',
          overflow: 'hidden',

          '& > *:nth-of-type(1)': {
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginRight: '-0.15em' // To avoid the ellipsis from being separated from the following text
          }
        }
      : {
          display: 'block',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontWeight: 'inherit',

          '& > *': {
            display: 'inline'
          }
        })
  }),

  ...(lines > 1 && {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'initial !important',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',

    '& > *': {
      display: 'block'
    }
  })
}))

export type EllipsisWithTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  children: ReactNode
  title?: string
  forceTooltip?: boolean
  textRef?: MutableRefObject<HTMLElement | null>
  ellipsisInFirstChild?: boolean
  lines?: number
  className?: string
}

const EllipsisWithTooltip = forwardRef(
  (
    {
      children,
      title,
      forceTooltip,
      textRef: inputTextRef,
      ellipsisInFirstChild,
      lines = 1,
      'aria-label': ariaLabel,
      className,
      ...tooltipProps
    }: EllipsisWithTooltipProps,
    ref
  ) => {
    const textElementRef = useRef<HTMLElement | null>(null)
    const [overflowing, setOverflowing] = useState<boolean>(false)
    const textRef = useMemo(() => inputTextRef || textElementRef, [inputTextRef, textElementRef])

    const elementTitle = title || textElementRef.current?.innerText || ''

    useEffect(() => {
      if (!children || !textRef.current) return

      if (ellipsisInFirstChild) {
        const childrenArray = Array.from(textRef.current.children)
        const [firstChild, ...restChildren] = childrenArray
        const totalContentWidth =
          (firstChild?.scrollWidth || 0) + restChildren.reduce((sum, child) => sum + (child?.scrollWidth || 0), 0)
        setOverflowing(forceTooltip ?? totalContentWidth > textRef.current.clientWidth)
      } else if (lines > 1) {
        setOverflowing(forceTooltip ?? textRef.current.scrollHeight > textRef.current.clientHeight)
      } else {
        setOverflowing(forceTooltip ?? textRef.current.scrollWidth > textRef.current.clientWidth)
      }
    }, [textRef, forceTooltip, children, ellipsisInFirstChild, lines])

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(textElementRef.current)
      } else if (ref) {
        ref.current = textElementRef.current
      }
    }, [ref])

    return (
      <TooltipRoot
        disableFocusListener={!overflowing}
        disableHoverListener={!overflowing}
        disableTouchListener={!overflowing}
        title={elementTitle}
        describeChild
        {...tooltipProps}
      >
        <Content
          aria-label={ariaLabel ?? elementTitle}
          ref={textElementRef}
          ellipsisInFirstChild={ellipsisInFirstChild}
          lines={lines}
          className={className}
        >
          {children}
        </Content>
      </TooltipRoot>
    )
  }
)

export default EllipsisWithTooltip
