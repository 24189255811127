import React, { PropsWithChildren, useRef } from 'react'
import { Box, Slide, styled, useTheme } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { AlertProps } from '@carto/meridian-ds/components'
import { DialogAlert } from './DialogAlert'
import { DialogActions } from './DialogFooter'

const Backdrop = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  zIndex: 100,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'hidden',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'flex-end',
  background: theme.palette.white[60]
}))

const AnimationWrapper = styled('div')(() => ({
  width: '100%'
}))

const BackdropContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5, 2, 2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,

  '.MuiDialogActions-root': {
    padding: 0,
    marginTop: theme.spacing(1)
  }
}))

interface Props extends AlertProps {
  onClickAway?(): void
  actions: React.ReactNode
  opened: boolean
}

export function DialogConfirmation({
  children,
  onClickAway,
  actions,
  severity = 'warning',
  opened,
  ...alertProps
}: PropsWithChildren<Props>) {
  const containerRef = useRef(null)
  const theme = useTheme()

  const handleClickAway = () => {
    if (onClickAway) {
      onClickAway()
    }
  }

  return (
    <Backdrop ref={containerRef}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Slide
          direction="up"
          in={opened}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
          easing={theme.transitions.easing.easeOut}
          timeout={350}
        >
          <AnimationWrapper>
            <BackdropContent>
              <DialogAlert severity={severity} {...alertProps}>
                {children}
              </DialogAlert>
              <DialogActions>{actions}</DialogActions>
            </BackdropContent>
          </AnimationWrapper>
        </Slide>
      </ClickAwayListener>
    </Backdrop>
  )
}
