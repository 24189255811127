import { Grid, Box, styled } from '@mui/material'
import { LinearProgress } from '@mui/material'
import MiscellaneousIcon from 'assets/icons/miscellaneous-services.svg?react'
import { Typography } from '@carto/meridian-ds/components'

const GridContent = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 99999,
  backgroundColor: theme.palette.grey[50]
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: theme.spacing(30.75)
}))

const GridInfoWrapper = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center'
}))

const Logo = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const GridInfoText = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(40),
  paddingLeft: theme.spacing(2.5),
  marginLeft: theme.spacing(1.5),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function SplashLoading({ imageUrl, message, title }: { imageUrl: string; message?: string; title?: string }) {
  return (
    <GridContent container justifyContent="center" alignItems="center" direction="column">
      <Logo>
        <img src={imageUrl} height="48" alt="logo" />
        {title && (
          <Typography variant="h4" weight="regular" ml={2}>
            {title}
          </Typography>
        )}
      </Logo>
      <Box mt={3}>
        <StyledLinearProgress />
      </Box>
      {message && (
        <GridInfoWrapper container mt={5}>
          <Grid item>
            <MiscellaneousIcon />
          </Grid>
          <GridInfoText item>
            <Typography variant="body2" component="div" color="textSecondary">
              {message}
            </Typography>
          </GridInfoText>
        </GridInfoWrapper>
      )}
    </GridContent>
  )
}

export default SplashLoading
